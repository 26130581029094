.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 20vmin;
  pointer-events: none;
}
.logo {
  height: 46px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table thead tr {
  background: linear-gradient(90deg, #5e2ded 0, #a384fc 100%);
  color: #fff;
}
img.full-image {
  width: 100%;
}
.header-logo img {
  width: 180px;
}

/*.custom_container {
  background: linear-gradient(90deg, #10c4fc 0, #7969fc 100%);
}*/
.navbar_fixed .custom_container {
  background: #fff;
}
/* .home_banner_area {
  padding-top: 95px;
} */

/* .app_banner_area {
  margin: 0 -15px;
  padding-top: 0 !important;
} */

/*
#banner-video {
  width: 100%;
}

.app_banner_content {
  position: absolute;
  top: 200px;
  right: 200px;
}
*/

.p_10 {
  padding: 10px;
}

.pt_10 {
  padding-top: 10px;
}

.pb_10 {
  padding-bottom: 10px;
}

.p_20 {
  padding: 20px;
}

.pt_20 {
  padding-top: 20px;
}

.pb_20 {
  padding-bottom: 20px;
}

.p_30 {
  padding: 30px;
}

.pt_30 {
  padding-top: 30px;
}

.pb_30 {
  padding-bottom: 30px;
}

.btn-apply-now {
  width: 100px;
  position: absolute;
  right: 20px;
  top: 25px;
}

.custom_container {
  padding: 0 20px;
}

.w_menu .nav-item .nav-link {
    color: #fff;
}

.navbar_fixed .header_area .w_menu .nav-item .nav-link {
    color: #051441;
}

 a.btn_get.btn_hover.new_btn {
    border: 2px solid #fff;
    box-shadow: none;
    padding: 0 30px;
    background: #fff;
    min-width: 120px;
    text-align: center;
    color: #6754e2;
}

/*.custom_container {
  background: #fff !important;
}*/
/*.custom_container #navbarSupportedContent ul a {
  color: #000 !important; 
}*/

.custom_container img {
  width: 200px;
}

.custom_container .logo1 {
  display: block;
}
.custom_container .logo2 {
  display: none;
}

.navbar_fixed .custom_container .logo1 {
  display: none;
}
.navbar_fixed  .custom_container .logo2 {
  display: block;
}

.custom_container ul li a.dropdown-toggle {
  color: #fff !important;
}

.navbar_fixed .custom_container .dropdown li a {
  color: #000 !important;
}

.navbar_fixed .menu > .nav-item > .nav-link {
  font: 500 15px "Poppins", sans-serif;
  color: #051441 !important;
}

.breadcrumb_area {
  background-image: linear-gradient(180deg, #0ec4fc 0%, #7969fc 100%) !important;
}
.navbar-nav.menu.ml-auto {
  margin: 0 auto !important;
}


.cp_step_img {
  -ms-transform: scale(1.7);
  -webkit-transform: scale(1.7);
  transform: scale(1.7);
}

.t-p-title {
  color: #0140be;
  font-size: 17px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 0px;
  text-transform: none;
}
.t-p-desc {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0px;
  text-transform: none;
}


.custom_container ul li a.dropdown-toggle {
  color: #fff !important;
}

@media (max-width: 991px) {
  .custom_container ul li a.dropdown-toggle {
    color: #051441 !important;
  }
}